(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var angular = require('angular'),
    router = require('angular-route'),
    ngAnimate = require('angular-animate'),
    ngTouch = require('angular-touch'),
    ngAria = require('angular-aria'),
    uibs = require('ui-bootstrap4'),
    ngSanitize = require('angular-sanitize'),
    ngMessages = require('angular-messages'),
    ngFileUpload = require('./ng-file-upload.min.js');

}).call(this,require("r7L21G"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_6cf8c02d.js","/")